'use strict';

import $ from 'jquery';

document.addEventListener('turbolinks:load', () => {
  updateMaxValue();
  updateMinValue();

  $('input.calorie_max').off('change').on('change', updateMinValue);

  $('input.calorie_min').off('change').on('change', updateMaxValue);
})

function updateMaxValue() {
  const maxInput = $('input.calorie_max')
  const minInput= $('input.calorie_min')
  if (maxInput.val() < minInput.val())  maxInput.val(minInput.val());
}

function updateMinValue() {
  const maxInput = $('input.calorie_max')
  const minInput= $('input.calorie_min')
  if (minInput.val() > maxInput.val()) minInput.val(maxInput.val());
}
