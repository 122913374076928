'use strict';


function selectAllCheckboxes() {
  const checkboxes = document.getElementById("location_id_collection").querySelectorAll('input[type=checkbox]')
  checkboxes.forEach((checkbox) => {
    checkbox.checked = true;
  })
};

function unselectAllCheckboxes() {
  const checkboxes = document.getElementById("location_id_collection").querySelectorAll('input[type=checkbox]')
  checkboxes.forEach((checkbox) => {
    checkbox.checked = false;
  })
}

document.addEventListener("turbolinks:load", () => {
  const selectAllButton = document.getElementById("select-all")
  const selectAllFn = selectAllCheckboxes;
  selectAllButton?.removeEventListener('click', selectAllFn, false);
  selectAllButton?.addEventListener('click', selectAllFn, false);

  const selectNoneButton = document.getElementById("select-none")
  const selectNoneFn = unselectAllCheckboxes;
  selectNoneButton?.removeEventListener('click', selectNoneFn, false);
  selectNoneButton?.addEventListener('click', selectNoneFn, false);
});
