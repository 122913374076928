import Tagify from '@yaireo/tagify';

document.addEventListener("turbolinks:load", () => {
  var input = document.querySelector('input[data-role="tags"]');

  if (!input) return;

  var initialValues = JSON.parse(input.dataset.initialValues || '[]');
  var tagWhitelist = JSON.parse(input.dataset.whiteList || '[]');
  const tagify = new Tagify(input, {
    whitelist: tagWhitelist,
    enforceWhitelist: true,
    dropdown: {
      enabled: 1,
      closeOnSelect: false
    },
  });
  tagify.addTags(initialValues, true);
});
