import $ from 'jquery';

$(document).on('turbolinks:load', () => {
    // only show "Update" button once a setting has been altered
    $('.settings form input').off('change').on('change', (event) => {
        const $form = $(event.target).closest('form');
        $form.closest('tr')
            .find(`[form="${$form.attr('id')}"`)
            .css({visibility: 'visible'});
    });
    // help prevent accidental settings changes
    $('.settings form').on('submit', (event) => {
        const prompt = $("#confirm-update").data("confirm-text");
        const proceed = confirm(prompt);

        if (!proceed) {
            event.preventDefault();
            return false;
        }
    });
});
