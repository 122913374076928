'use strict';

import $ from 'jquery';

document.addEventListener('turbolinks:load', () => {
  $('.location form :input').off('change').on('change', (e) => {
    let element = e.currentTarget;
    const url = $('.location form').attr('action');
    const formData = new FormData();

    formData.append('authenticity_token', $('.location form input[name=authenticity_token]').val());

    if (element.type === 'checkbox') {
      formData.append(element.name, element.checked);
      element = document.querySelector(`#${element.id.match(/(.*)_for_sale/)[1]}_id`);
    }
    formData.append(element.name, element.value);

    fetch(url, { method: 'PATCH', body: formData })
      .then(checkStatus)
      .then(() => location.reload(true))
      .catch((error) => {
        return error.response;
      })
      .then((data) => {
        if (data) alert(data);
      });
  });
});

const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    const error = new Error(response.statusText);
    error.response = response.json();
    throw error;
  }
}
