'use strict';

import $ from 'jquery';

document.addEventListener('turbolinks:load', () => {
  $('form.incrementable').off('submit').on('submit', function (e) {
    e.preventDefault();

    const url = $(this)[0].action;
    const method = $(this)[0].method;
    const body = $(this).closest('form').serializeArray()
    // Use the Fetch API to make the request
    fetch(url, {
      method: method,
      // Include credentials to support secure CSRF-protected forms
      credentials: 'include',
      body: body,
      headers: {
        // Rails uses X-CSRF-Token for secure AJAX requests, fetch it from the meta tag
        'content-type': 'application/x-www-form-urlencoded',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    }).then(() => location.reload())
      .catch((err) => err.message);
  });
})
