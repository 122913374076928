/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Turbolinks from "turbolinks"
Turbolinks.start()
import Rails from '@rails/ujs';
Rails.start();

import './location';
import './settings';
import './incrementable';

import '../images/favicon.ico';

import './menu/inventory_item';
import './menu/tag_input_listener';
import './menu/calorie_inputs';

import './clickable.jquery';
import './sortable.jquery'

// Workaround to ensure tokens are refreshed for form submission.
import 'jquery';
$( document ).on('turbolinks:load', function() {
  $.rails.refreshCSRFTokens();
})
